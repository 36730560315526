<template>
  <div class="create_outbound">
    <div class="mb-15 flex flex-jb" v-if="false">
      <div>
        <template v-if="detailFlag">
          <el-button size="small" type="primary" v-if="isAllMachineInHouse" @click="newlyBuild">保存并新建</el-button>
        <!-- 填好了单据信息-按钮变亮 -->
        <el-button
          v-if="warehouseId && supplierId && orderInfo.phoneName && orderInfo.imei && orderInfo.expressNo && (orderInfo.expressIsTrue === false ? orderInfo.expressNoExpand : true) && isAllMachineInHouse"
          style="margin-left:30px" size="small" type="success" icon="el-icon-folder-checked" :loading="btnLoading"
          @click="preservation('save')">保存</el-button>
          <!-- 否则按钮保存置灰 -->
        <el-button v-else style="margin-left:30px" size="small" type="info" icon="el-icon-folder-checked"
          @click="infoChecked">保存</el-button>
        </template>
        <el-button v-if="intoNo" style="margin-left:30px" size="small" type="warning" icon="el-icon-printer"
          @click="printBatchNo">打印串号</el-button>
        <el-button v-else style="margin-left:30px" size="small" type="info" icon="el-icon-printer"
          @click="infoPrinter">打印串号</el-button>
      </div>
      <div style="color:#FF1200" v-if="!isAllMachineInHouse">
        该单据已出库，不可修改信息。
      </div>
    </div>
    <div class="card mb-15">
      <div class="card_header bottom-border flex">
        <img class="icon" src="./imgs/icon_jjrk_1.png" alt="" />
        <span class="fs-16 font-bold">旧机入库</span>
        <div class="fs-14 txt-2 flex" style="margin-left:70px">
          <div>
            <span class="font-bold">入库单据：</span>
            <span>{{ intoNo || '保存后自动生成' }}</span>
          </div>
          <div style="margin-left:60px">
            <span class="font-bold">制单日期：</span>
            <span>{{ createTime }}</span>
          </div>
          <div style="margin-left:60px">
            <span class="font-bold">制单人：</span>
            <span>{{ operationUser }}</span>
          </div>
        </div>
      </div>
      <div class="card_body">
        <div class="card_bg_box flex">
          <div style="width:300px;margin-right:50px;display:flex;" v-if="detailFlag">
              <el-button :disabled="isSave" size="small" style="margin-right:20px;" type="primary"
                icon="el-icon-search" @click="searchOrder">查询订单录入</el-button>
              <el-button :disabled="isSave" size="small" type="warning" @click="manualEntry">手动录入机器</el-button>  
          </div>
          <div>
            <span>选择仓库</span>
            <el-select style="margin-left:10px" size="small" disabled v-model="warehouseId" placeholder="请选择仓库">
              <el-option v-for="item in warehouseList" :key="item.id" :label="item.warehouseName" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="ml-50">
            <span>入库商家</span>
            <el-select :disabled="!isAllMachineInHouse || !detailFlag || (isDefault && !machines.recycleOrderNo)"
              style="margin-left:10px" size="small" clearable filterable v-model="supplierId" placeholder="请选择入库商家"
              :filter-method="dataFilter"  @visible-change="visibleyouchange">
              <el-option v-for="item in exchangeUnitList2" :key="item.id" :label="item.exchangeUnitName" :value="item.id" :disabled="item.disabled">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-15">
      <div style="display:flex;justify-content: space-between;align-items: center;padding-right:18px">
        <div class="card_header flex">
          <img class="icon" src="./imgs/icon_jjrk_2.png" alt="" />
          <span class="fs-16 font-bold">填写入库单</span>
        </div> 
       <div v-if="manualFlag && detailFlag"><el-button size="mini" type="primary" plain  @click="manualEntry(orderInfo)">编辑信息</el-button></div>  
      </div>
      <div class="card_body">
        <el-table :data="orderInfoArr" border id="order" empty-text="请先查询订单或手动录入订单">
          <el-table-column prop="phoneName" label="旧机名称" :render-header="renderHeader1" align="center" width="220px">
            <template slot-scope="{ row }">
              <div v-if="!manualFlag && detailFlag">
                <span v-if="!isAllMachineInHouse">{{ row.phoneName || '' }}</span>
                <el-input v-else size="mini" clearable v-model="row.phoneName" placeholder="请输入旧机名称"></el-input>
                <div v-if="isAllMachineInHouse " class="flex flex-jb mt-10">
                  <el-tag size="mini" style="cursor: pointer;" v-for="(item, index) in phoneNameList" :key="index"
                    @click="selectPhoneName(item)" effect="plain">
                    {{ item }}
                  </el-tag>
                </div>
              </div>
              <div v-else>{{ row.phoneName || '' }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="barCode" label="条码信息" align="center">
            <template slot-scope="{ row }">
              <div v-if="!manualFlag && detailFlag">
                <span v-if="!isAllMachineInHouse">{{ row.barCode || '' }}</span>
                <el-input v-else size="mini" clearable v-model.trim="row.barCode" placeholder="请输入绑定条码"></el-input>
              </div>
              <div v-else>{{ row.barCode || '--' }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="imei" label="旧机串号" :render-header="renderHeader2" align="center">
            <template slot-scope="{ row }">
              <div v-if="!manualFlag && detailFlag">
                <span v-if="!isAllMachineInHouse">{{ row.imei || '' }}</span>
                <el-input v-else size="mini" maxlength="15" onKeyUp="value=value.replace(/[\W]/g,'')" clearable
                  v-model.trim="row.imei" placeholder="请输入旧机串号"></el-input>
              </div>
              <div v-else>{{ row.imei || '' }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="expressNo" label="快递信息" :render-header="renderHeader3" align="center">
            <template slot-scope="{ row }">
              <div v-if="!manualFlag && detailFlag">
                <span v-if="!isAllMachineInHouse">{{ row.expressNo || '' }}</span>
                <el-input v-else size="mini" clearable v-model.trim="row.expressNo" placeholder="请输入快递单号"></el-input>
              </div>
              <div v-else>{{ row.expressNo || '' }}</div>
            </template>
          </el-table-column>
          <el-table-column v-if="machines.recycleOrderNo" prop="expressIsTrue" label="是否与实际快递一致"
            :render-header="renderHeader4" align="center">
            <template slot-scope="{ row }">
              <el-switch v-model="row.expressIsTrue" :disabled="!detailFlag" active-text="是" inactive-text="否"
                @change="switchChange($event, row)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column v-if="machines.recycleOrderNo " prop="expressNoExpand" label="实际快递单号"
            :render-header="renderHeader5" align="center">
            <template slot-scope="{ row }">
              <span v-if="row.expressIsTrue">--</span>
              <div v-else>
                <span v-if="!isAllMachineInHouse || !detailFlag">{{ row.expressNoExpand || '' }}</span>
                <el-input v-else size="mini" clearable v-model.trim="row.expressNoExpand"
                  placeholder="请输入实际快递单号"></el-input>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="recycleOrderNo" label="订单信息" align="center">
            <template slot-scope="{ row }">
              <span v-if="row.orderNo" style="color:#2384fc;cursor: pointer;" @click="copy(row.orderNo)">{{
                row.orderNo
              }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column v-if="!manualFlag" prop="ackTime" label="成交时间" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.ackTime || "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column v-if="!manualFlag" prop="merchantName" label="成交回收商" align="center">
            <template slot-scope="{ row }">
              <div v-if="row.isCurrentMerchantAck">
                <el-tooltip
                  v-if="row.merchantName"
                  class="item"
                  effect="dark"
                  :content="row.merchantName"
                  placement="top"
                >
                  <div class="Remarks2">{{ row.merchantName }}</div>
                </el-tooltip>
                <span v-else>--</span>
              </div>
              <div v-else style="color:red">非您商户,请选暂存入库</div>
            </template>
          </el-table-column>
          <el-table-column v-if="manualFlag" prop="purchasePrice" label="采购价（元）" align="center">
            <template slot-scope="{ row }">
              <!-- <span v-if="!row.isCurrentMerchantAck">--</span>
              <span v-else>￥{{ row.purchasePrice || '--' }}</span> -->
              <span>￥{{ row.purchasePrice || '--' }}</span>
            </template>
          </el-table-column>   
         
        </el-table>
        <div class="img_box" v-if="machines.recycleOrderNo && orderInfo && orderInfo.images && orderInfo.images.length">
          <img class="left_btn" @click="handleScrollLeft" src="./imgs/icon_zjt.png" alt="">
          <img class="right_btn" @click="handleScrollRight" src="./imgs/icon_yjt.png" alt="">
          <div ref="barContainer" class="img_list">
            <el-image class="img_item" fit="contain" v-for="(item, index) in orderInfo.images" :key="index"
              :src="item.smallImg || item.img" :preview-src-list="[item.img]" />
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card_header flex">
        <img class="icon" src="./imgs/icon_jjrk_3.png" alt="" />
        <span class="fs-16 font-bold">备注内容</span>
      </div>
      <div class="card_body">
        <div class="fast_note_list flex mb-15">
          <div class="note_item flex flex-jc" v-for="item in fastNoteList" :key="item.id" @click="selectNote(item)">
            {{ item.note }}</div>
        </div>
        <el-input :disabled="!isAllMachineInHouse" type="textarea" placeholder="请输入备注内容" v-model="remark" maxlength="200"
          show-word-limit>
        </el-input>
      </div>
    </div>
    <el-dialog title="查询入库订单" :visible.sync="orderListShow" :close-on-click-modal="false" width="1270px"
      @closed="orderListClosed">
      <div style="color:red;margin-bottom:20px;"> 说明：目前仅支持查询繁星采购的订单，若订单并未与您成交，默认入库到【繁星暂存】 </div>
      <el-form :inline="true" size="small">
        <el-form-item label="背贴条码">
          <el-input style="width:170px" v-model.trim="barCode" placeholder="请输入背贴码查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="快递单号">
          <el-input style="width:170px" v-model.trim="expressNo" placeholder="请输入快递单号查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="旧机串号">
          <el-input style="width:170px" v-model.trim="imei" placeholder="请输入旧机串号查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="订单号">
          <el-input style="width:170px" v-model.trim="orderNo" placeholder="请输入订单号查询" clearable></el-input>
        </el-form-item>        
        <el-form-item>
          <el-button type="primary" @click="sureSearch">查询</el-button>
        </el-form-item>
        <!-- <el-form-item v-if="orderList.length">
          <div class="flex font-bold" style="font-size:12px;">
            <span>无法查询到对应订单，可选择</span>
            <span style="color:#FF687B;text-decoration: underline;cursor: pointer;" @click="selectStaging">暂存入库</span>
          </div>
        </el-form-item> -->
      </el-form>
      <!-- v-if="!orderList.length" -->
      <div class="flex font-bold flex-jc" :style="{height:orderList.length ? '60px' :'300px'}" style="font-size:12px;">
        <span>出现以下情况</span>
        <span style="color:#FF687B;">入繁星暂存</span>
        <span>，平台将会有专人与您对接处理；1.订单已取消/已退回 2.包裹来源于繁星，但并非与您成交</span>
      </div>
      <div style="overflow-y: auto; max-height: 600px" v-if="orderList.length">
        <div class="order-list"
          :class="{ 'order-list-01': item.state == '01' || item.state == '02' || item.state == '03' || item.state == '04' || item.state == '05', 'order-list-02': item.state == '00' || item.state == '10' || item.state == '20' || item.state == '30' }"
          v-for="(item, index) in orderList" :key="index">
          <el-table :data="[item]" border id="order">
            <el-table-column prop="orderNo" label="订单号" align="center">
              <template slot-scope="{ row }">
                <div>{{ row.orderNo }}</div>
                <el-tag
                  :type="row.state == '01' || row.state == '02' || row.state == '03' || row.state == '04' || row.state == '05' ? '' : 'info'"
                  size="small">
                  <span v-if="row.state === '00'">待确认</span>
                  <span v-else-if="row.state === '01'">已确认</span>
                  <span v-else-if="row.state === '02'">待支付</span>
                  <span v-else-if="row.state === '03'">已绑码</span>
                  <span v-else-if="row.state === '04'">已收货</span>
                  <span v-else-if="row.state === '05'">降价收货</span>
                  <span v-else-if="row.state === '10'">已取消</span>
                  <span v-else-if="row.state === '20'">已作废</span>
                  <span v-else-if="row.state === '30'">已退回</span>
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="images" label="旧机图片" align="center">
              <template slot-scope="{ row }">
                <div class="images-box" v-if="row.images && row.images.length > 0">
                  <el-image style="width: 50px; height: 50px" :src="row.images[0].smallImg || row.images[0].img"
                    :preview-src-list="row.imgList" />
                </div>
                <div v-else>暂无图片</div>
              </template>
            </el-table-column>
            <el-table-column prop="phoneName" label="旧机名称" align="center">
              <template slot-scope="{ row }">
                <span>{{ row.phoneName || '--' }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="barCode" label="背贴条码" align="center">
              <template slot-scope="{ row }">
                <span v-if="row.barCode" :style="{ color: searchForm.barCode ? '#FF0024' : '' }">{{ row.barCode }}</span>
                <span v-else>--</span>
              </template>
            </el-table-column>
            <el-table-column prop="imei" label="旧机串号" align="center">
              <template slot-scope="{ row }">
                <span v-if="row.imei" :style="{ color: searchForm.imei ? '#FF0024' : '' }">{{ row.imei }}</span>
                <span v-else>--</span>
                <div v-if="row.imeiInWarehouse == true">
                  <el-tag size="mini" type="danger" effect="plain">
                    已在库
                  </el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="expressNo" label="快递信息" align="center">
              <template slot-scope="{ row }">
                <span v-if="row.expressNo" :style="{ color: searchForm.expressNo ? '#FF0024' : '' }">{{ row.expressNo
                }}</span>
                <span v-else>--</span>
              </template>
            </el-table-column>
            <el-table-column prop="ackTime" label="成交时间" align="center">
              <template slot-scope="{ row }">
                <span>{{ row.ackTime || '--' }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="companyName" label="下单商户" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  v-if="row.companyName&&row.isCurrentMerchantAck"
                  class="item"
                  effect="dark"
                  :content="row.companyName"
                  placement="top"
                >
                  <div class="Remarks2">{{ row.companyName }}
                  </div>
                </el-tooltip>
                <div class="Remarks2" v-if="!row.isCurrentMerchantAck">**门店商户</div>
                <!-- <span v-if="!row.companyName">--</span> -->
              </template>
            </el-table-column>
            <el-table-column prop="merchantName" label="成交回收商" align="center">
              <!-- <template slot-scope="{ row }">
                <el-tooltip
                  v-if="row.merchantName&&row.isCurrentMerchantAck"
                  class="item"
                  effect="dark"
                  :content="row.merchantName"
                  placement="top"
                >
                  <div class="Remarks2">{{ row.merchantName }}
                  </div>
                </el-tooltip>
                <div class="Remarks2" style="color:red" v-if="!row.isCurrentMerchantAck">非您商户,请选暂存入库</div>
              </template> -->
              <template slot-scope="{ row }">
                <div v-if="row.isCurrentMerchantAck">
                  <el-tooltip
                    v-if="row.merchantName"
                    class="item"
                    effect="dark"
                    :content="row.merchantName"
                    placement="top"
                  >
                    <div class="Remarks2">{{ row.merchantName }}</div>
                  </el-tooltip>
                  <span v-else>--</span>
                </div>
                <div v-else style="color:red">非您商户,请选暂存入库</div>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="ackPrice" label="成交金额" align="center">
              <template slot-scope="{ row }">
                <span>￥{{ row.ackPrice || '--' }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="revertCompanyName" label="归属门店商户" align="center">
              <template slot-scope="{ row }">
                <span>{{ row.revertCompanyName || '--' }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="revertAckPrice" label="归属商户成交金额" align="center">
              <template slot-scope="{ row }">
                <span>￥{{ row.revertAckPrice || '--' }}</span>
              </template>
            </el-table-column> -->
            <el-table-column prop="purchasePrice" label="采购金额（元）" align="center">
              <template slot-scope="{ row }">
                <span v-if="!row.isCurrentMerchantAck">--</span>
                <span v-else>￥{{ row.purchasePrice || '--' }}</span>
              </template>
            </el-table-column>     
            <el-table-column prop="operation" label="操作" align="center">
              <template slot-scope="{ row }">
                <el-button size="mini" type="primary" @click="selectOrder(row)">选择入库</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div style="height:20px"></div>
    </el-dialog>
    <el-dialog title="操作提示" :visible.sync="newTipShow" :close-on-click-modal="false" width="400px">
      <div class="enable-tip">
        <div class="tip-sure">
          当前单据还未填写完，无法保存。是否确认不保存新建单据？
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="newTipShow = false">取消</el-button>
        <el-button type="primary" @click.native="newSubmit">确定</el-button>
      </span>
    </el-dialog>
    <!-- 保存时有重复串号的弹框 -->
    <el-dialog title="操作提示" :visible.sync="newTipShow2" :close-on-click-modal="false" width="420px">
      <div class="enable-tip">
        <div class="tip-sure">
          该串号为多次入库，可能为异常机，请确认是否继续入库？
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="newTipShow2 = false">取消</el-button>
        <el-button type="primary" @click.native="add">确定</el-button>
      </span>
    </el-dialog>
    <!-- 手动录入机器 -->
    <el-dialog
      title="手动录入机器"
      :visible.sync="manualEntryShow"
      :close-on-click-modal="false"
      width="600px"
      @closed="handleDialogClosed"
    >
      <el-form
        ref="manualForm"
        label-position="left"
        :rules="manualRules"
        :model="manualForm"
        label-width="80px"
      >
        <el-form-item label="机型" prop="phoneName">
          <el-input
            v-model="manualForm.phoneName"
            clearable
            maxlength="50"
            placeholder="请输入旧机机型"
          ></el-input>
          <div style="width:240px" class="flex flex-jb mt-10">
            <el-tag style="cursor: pointer;" v-for="(item, index) in phoneNameList" :key="index"
              @click="manualSelectPhoneName(item)" effect="plain">
              {{ item }}
            </el-tag>
          </div>
        </el-form-item>  
        <el-form-item label="旧机串号" prop="imei">
          <div style="display:flex;">
            <el-input
              v-model.trim="manualForm.imei"
              clearable
              maxlength="15"
              placeholder="请输入旧机串号"
            ></el-input>
            <div style="margin-left:20px;">不能开机，点击<span @click="getRandomImei" style="color:#0981ff;text-decoration:underline;cursor:pointer;">随机生成</span></div>
          </div>
        </el-form-item>
        <el-form-item label="旧机条码">
          <el-input
            v-model="manualForm.barCode"
            clearable
            maxlength="50"
            placeholder="请输入旧机条码"
          ></el-input>
        </el-form-item>
        <el-form-item label="快递信息" prop="expressNo">
          <el-input
            v-model="manualForm.expressNo"
            clearable
            maxlength="50"
            placeholder="请输入收货快递信息"
          ></el-input>
        </el-form-item>
        <el-form-item label="采购价" prop="purchasePrice">
          <div style="display:flex;">
            <el-input
              v-model="manualForm.purchasePrice"
              clearable
              placeholder="请输入采购价"
            ></el-input>
            <span style="margin-left:20px;">元</span>
          </div>
        </el-form-item>   
        <el-form-item label="订单号">
          <el-input
            v-model="manualForm.orderNo"
            clearable
            maxlength="50"
            placeholder="请输入订单号"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="manualEntryShow = false">取消</el-button>
        <el-button type="primary" @click.native="handleSuccess">确认添加</el-button>
      </span>
    </el-dialog>
    <!-- 有手动录入切换至查询录入 -->
    <!-- <el-dialog title="操作提示" :visible.sync="toggleTipShow" :close-on-click-modal="false" width="400px">
      <div class="enable-tip">
        <div class="tip-sure">
          【文本内容待确定】 当前单据还未保存，切换至查询录入，将会清空。是否确认不保存新建单据？
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="toggleTipShow = false">取消</el-button>
        <el-button type="primary" @click.native="toggleSubmit">确定</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import _api from "@/utils/request";
import { getLodop } from "@/utils/LodopFuncs";
import { getRandomIemi } from "@/utils/common";
let checkNum = (rule, value, callback) => {
  const reg = /^\d{1,5}(\.\d{1,2})?$/; 
  if (!reg.test(value)) {
    callback(new Error('最多5位数字，且最多两个小数'));
  } else {
    callback();
  }
};

export default {
  name: "create_outbound",
  data() {
    return {
      isSave:false,
      detailFlag:true,
      orderInfoArr:[],
      manualFlag:false,//手动创建的标识
      manualEntryShow:false,// 手动录入弹窗
      manualForm: {
        phoneName: "", //旧机机型
        imei:"",//旧机换号
        barCode:"",//旧机条码  orderUnique
        expressNo:"",//快递信息
        purchasePrice:"",//采购价
        orderNo:"",//订单号
      },
      manualRules: {
        phoneName: [
          { required: true, message: "请输入旧机机型", trigger: "blur" },
        ],
        imei:[
          { required: true, message: "请输入旧机串号", trigger: "blur" },
        ],
        purchasePrice: [
          { required: true, message: "请输入采购价", trigger: "blur" },
          { validator: checkNum, trigger: "blur" },
        ],
        expressNo: [
          { required: true, message: "请输入快递信息", trigger: "blur" },
        ],
      },
      // toggleTipShow:false, //手动录入切换至查询录入
      intoNo: "",
      isAllMachineInHouse: true,
      btnLoading: false,
      operationUser: "",
      createTime: "",
      warehouseList: [],
      warehouseId: "",
      exchangeUnitList: [],
      exchangeUnitList2: [],
      fastNoteList: [],
      supplierId: "",
      remark: "",
      // orderInfo: {
      //   phoneName: "",
      //   expressIsTrue: true,
      //   barCode: "",
      //   imei: "",
      //   expressNo: "",
      //   expressNoExpand: "",
      // },  
      orderInfo:{},
      machines: {
        barCode: '',
        expressIsTrue: true,
        expressNo: '',
        expressNoExpand: '',
        imei: '',
        phoneName: '',
        recycleOrderNo: '',
      },
      phoneNameList: ["二手手机", "二手电脑", '二手平板'],
      newTipShow: false,
      // 保存时有重复串号的弹框
      newTipShow2: false,
      // 查询订单弹框
      orderListShow: false,
      orderList: [],
      barCode: "",
      expressNo: "",
      imei: "",
      orderNo:"",
      searchForm: {
        barCode: "",
        expressNo: "",
        imei: "",
      },
      offset: 360,
      isDefault: false,
      saveType: ''
    };
  },
  created() {
    this.createTime = moment(new Date()).format("YYYY-MM-DD")
    this.operationUser = localStorage.getItem('userName') || ""
    if (this.$route.query.intoNo) {
      this.intoNo = this.$route.query.intoNo || ""
      this.detailFlag = false
      this.warehouseInfo('get')
    }
    this.warehouseSelect();
    this.supplierIntoSelect()
    this.getFastNote()
  },
  mounted() {
    if (this.$refs.barContainer) {
      this.$refs.barContainer.scrollLeft = 0
    }
  },
  watch:{
    orderInfo:{
      handler(datanew){
        if(datanew.imei || datanew.orderNo){
            this.orderInfoArr=[datanew]
        }else{
          this.orderInfoArr=[]
        }
      }
    }
  },
  methods: {
    // 随机生成串号
    getRandomImei(){
      this.manualForm.imei = getRandomIemi()
    },
    // 打开关闭手动录入弹窗
    manualEntry(data){
      if (data && this.manualFlag) {
        this.manualForm.phoneName = data.phoneName;
        this.manualForm.imei = data.imei;
        this.manualForm.barCode = data.barCode;
        this.manualForm.expressNo = data.expressNo;
        this.manualForm.purchasePrice = data.purchasePrice;
        this.manualForm.orderNo = data.orderNo;
      }
      this.manualEntryShow = true
    },
    //关闭手动录入弹窗
    handleDialogClosed() {
      this.$refs["manualForm"].resetFields();
      this.manualForm = {
        phoneName: "", //旧机机型
        imei:"",//旧机换号
        barCode:"",//旧机条码  orderUnique
        expressNo:"",//快递信息
        purchasePrice:"",//采购价
        orderNo:"",//订单号
      }
    },
      // 确认添加
    handleSuccess() {
      this.$refs["manualForm"].validate((valid) => {
        if (valid) {
          console.log('确认添加',this.manualForm,this.orderInfo);
          this.orderInfo =  {...this.manualForm}
          this.machines.recycleOrderNo = this.manualForm.orderNo,
          this.orderInfo.expressIsTrue = true
          this.manualFlag = true
          this.$message.success("操作成功!")
          this.manualEntryShow = false
          // this.selectSupplier()
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    warehouseSelect() {
      _api.warehouseSelect({merchantId:this.$route.query.merchantId}).then((res) => {
        if (res.code == 1) {
          this.warehouseList = res.data;
          this.warehouseId = res.data[0].id
        }
      });
    },
    // 获取入库商家
    supplierIntoSelect() {
      _api.supplierIntoSelect({merchantId:this.$route.query.merchantId}).then((res) => {
        if (res.code == 1) {
          this.exchangeUnitList = res.data;
          this.exchangeUnitList2 = res.data
        }
      });
    },
    // 手动添加后，选择仓库只能选择 非繁星、非繁星暂存；查询录入，均可选
    selectSupplier(){
      if(!this.manualFlag){
        this.exchangeUnitList2.map(item => { 
          if(!item.isFxDefault){
            item.disabled = true
          }
        })
        this.supplierId = ""
      }
      // if(this.manualFlag){
      //   this.exchangeUnitList2.map(item => { 
      //     if(item.isFxDefault){
      //       item.disabled = true
      //     }
      //   })
      //   this.supplierId = ""
      // }else{
      //   this.exchangeUnitList2.map(item => { 
      //       item.disabled = false
      //       console.log('22',item);
      //   })
      // }
    },
    // 查看快捷备注
    getFastNote() {
      _api.getFastNote({ source: '01' ,merchantId:this.$route.query.merchantId}).then((res) => {
        if (res.code == 1) {
          this.fastNoteList = res.data;
        }
      });
    },
    renderHeader1(h) {
      return (
        <div>
          <span style="color:#FF1200;margin-right:5px">*</span>
          <span>旧机名称</span>
        </div>
      );
    },
    renderHeader2(h) {
      return (
        <div>
          <span style="color:#FF1200;margin-right:5px">*</span>
          <span>旧机串号</span>
        </div>
      );
    },
    renderHeader3(h) {
      return (
        <div>
          <span style="color:#FF1200;margin-right:5px">*</span>
          <span>快递信息</span>
        </div>
      );
    },
    renderHeader4(h) {
      return (
        <div>
          <span style="color:#FF1200;margin-right:5px">*</span>
          <span>是否与实际快递一致</span>
        </div>
      );
    },
    renderHeader5(h) {
      return (
        <div>
          <span style="color:#FF1200;margin-right:5px">*</span>
          <span>实际快递单号</span>
        </div>
      );
    },
    searchOrder() {
      // if(this.manualFlag){
      //   this.toggleTipShow = true
      // }else{
      //   this.orderListShow = true
      // }
      this.orderListShow = true
    },
    // 订单弹框关闭
    orderListClosed() {
      this.orderList = []
      this.imei = ""
      this.expressNo = ""
      this.barCode = ""
      this.orderNo = ""
    },
    sureSearch() {
      this.getOrderList()
    },
    getOrderList() {
      this.searchForm = {
        barCode: this.barCode,
        expressNo: this.expressNo,
        imei: this.imei,
        orderNo: this.orderNo,
        merchantId:this.$route.query.merchantId
      }
      _api.warehouseFindOrder(this.searchForm).then(res => {
        if (res.code == 1) {
          this.orderList = res.data
          this.orderList.forEach(item => {
            item.imgList = []
            item.images.forEach(ele => {
              item.imgList.push(ele.img)
            })
          })
        }
      })
    },
    // 选择入库
    selectOrder(row) {
      this.manualFlag = false
      this.orderListShow = false
      this.orderInfo = row
      this.orderInfo.expressIsTrue = true
      if (row.isCurrentMerchantAck){ 
        // 繁星订单且与该商户 -- 繁星
        const arr = this.exchangeUnitList.filter(item => item.exchangeUnitName == '繁星回收')
        this.supplierId = arr[0].id
      } else{
        // 繁星订单但非与该商户成交 -- 繁星暂存
        const arr = this.exchangeUnitList.filter(item => item.exchangeUnitName == '繁星回收暂存')
        this.supplierId = arr[0].id
      }
      this.selectSupplier() 
      this.machines = {
        barCode: row.barCode,
        expressIsTrue: true,
        expressNo: row.expressNo,
        expressNoExpand: '',
        imei: row.imei,
        phoneName: row.phoneName,
        recycleOrderNo: row.orderNo,
      }
    },
    selectBlurC(e) {
      console.log(e);
      this.orderInfo.phoneName = e.target.value
      this.$forceUpdate();
    },
    // 设置旧机名称
    phoneNameInput(val) {
      this.orderInfo.phoneName = val
      this.$forceUpdate();
    },
    selectPhoneName(val) {
      this.orderInfo.phoneName = val
      this.$forceUpdate();
    },

    manualSelectPhoneName(val) {
      this.manualForm.phoneName = val
      this.$forceUpdate();
    },
    // 是否一致
    switchChange(val, row) {
      console.log('22',val,row);
      // this.orderInfo.expressIsTrue = val
      // this.orderInfo = Object.assign({}, this.orderInfo)

      this.orderInfoArr[0].expressIsTrue = val
      this.orderInfo = Object.assign({}, this.orderInfoArr[0])

      // this.$nextTick(()=>{
      //   this.orderInfoArr[0].expressIsTrue = val
      // })
    },
    copy(val) {
      const oInput = document.createElement("input");
      oInput.value = val;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = "oInput";
      oInput.style.display = "none";
      document.body.removeChild(oInput);
      this.$message.success("复制成功");
    },
    // 点击快捷备注
    selectNote(item) {
      if (!this.isAllMachineInHouse) {
        return
      }
      this.remark = this.remark ? this.remark + '、' + item.note : this.remark + item.note
    },
    // 选择暂存
    // selectStaging() {
    //   this.isDefault = false
    //   this.exchangeUnitList.forEach(item => {
    //     if (item.isDefault) {
    //       this.supplierId = item.id
    //       this.isDefault = true
    //     }
    //   })
    //   this.orderListShow = false
    //   this.remark = ""
    //   this.orderInfo = {
    //     phoneName: "",
    //     expressIsTrue: true,
    //     barCode: "",
    //     imei: "",
    //     expressNo: "",
    //     expressNoExpand: "",
    //   }
    //   this.machines = {
    //     barCode: '',
    //     expressIsTrue: true,
    //     expressNo: '',
    //     expressNoExpand: '',
    //     imei: '',
    //     phoneName: '',
    //     recycleOrderNo: '',
    //   }
    // },
    // 保存
    preservation(type) {
      this.saveType = type
      // this.machines = {
      //   barCode: this.orderInfo.barCode || "",
      //   expressIsTrue: this.orderInfo.expressIsTrue,
      //   expressNo: this.orderInfo.expressNo,
      //   expressNoExpand: this.orderInfo.expressNoExpand,
      //   imei: this.orderInfo.imei,
      //   phoneName: this.orderInfo.phoneName || "",
      //   recycleOrderNo: this.orderInfo.orderNo || "",
      //   purchasePrice: this.orderInfo.purchasePrice || "",
      // }
      this.machines = {
        barCode: this.orderInfoArr[0].barCode || "",
        expressIsTrue: this.orderInfoArr[0].expressIsTrue,
        expressNo: this.orderInfoArr[0].expressNo,
        expressNoExpand: this.orderInfoArr[0].expressNoExpand,
        imei: this.orderInfoArr[0].imei,
        phoneName: this.orderInfoArr[0].phoneName || "",
        recycleOrderNo: this.orderInfoArr[0].orderNo || "",
        purchasePrice: this.orderInfoArr[0].purchasePrice || "",
      }
      console.log('我是保存',this.orderInfo, this.machines);
      let params = {
        enterType: this.manualFlag ? '01' : '00',
        machines: [this.machines],
        remark: this.remark,
        supplierId: this.supplierId,
        warehouseId: this.warehouseId,
        merchantId:this.$route.query.merchantId
      }
      this.btnLoading = true
      if (this.intoNo) {//有入库单号时调更新入库单据接口
        _api.warehouseIntoUpdate({ intoNo: this.intoNo, ...params }).then(res => {
          if (res.code == 1) {
            this.$message.success('保存成功')
            if (this.saveType === 'new') {//new：保存并新建
              this.newSubmit()
              console.log('详情0');
            } else {
              console.log('详情1');
              this.warehouseInfo('get')
            }
          }
          this.btnLoading = false
        }).catch(() => {
          this.btnLoading = false
        })
      } else {//没有入库单号调串号跟踪
        _api.imeiTrack({ documentNo: this.intoNo, imei: this.orderInfo.imei,merchantId:this.$route.query.merchantId }).then(res => {
          console.log(res, '+++++++++++++');
          if (res.data.length > 0) {//有重复串号
            this.newTipShow2 = true
            this.btnLoading = false
          } else {//没有重复串号，新建入库单据
            this.add()
          }
        })
      }
    },
    // 添加
    add() {
      console.log(this.saveType);
      let params = {
        machines: [this.machines],
        remark: this.remark,
        supplierId: this.supplierId,
        warehouseId: this.warehouseId,
        enterType:this.manualFlag ? '01' : '00'
      }
      _api.warehouseIntoBuild(params).then(res => {
        if (res.code == 1) {
          this.$message.success('旧机已入库')
          this.intoNo = res.data
          if (this.saveType === 'new') {
            this.newSubmit()
          } else {
            this.warehouseInfo('save')
          }
        }
        this.btnLoading = false
      }).catch(() => {
        this.btnLoading = false
      })
    },
    // 入库单据详情
    warehouseInfo(type) {
      console.log('详情2');
      _api.warehouseInfo({ intoNo: this.intoNo ,merchantId:this.$route.query.merchantId}).then(res => {
        if (res.code == 1) {
          console.log('lalala',res.data);
          this.isAllMachineInHouse = res.data.isAllMachineInHouse
          this.remark = res.data.intoRemark || ""
          this.supplierId = res.data.intoSupplierId
          this.createTime = res.data.intoTime
          this.operationUser = res.data.intoUserName
          this.warehouseId = res.data.intoWarehouseId
          this.orderInfoArr = res.data.machines
          // this.orderInfo.barCode = res.data.machines[0].barCode
          // this.orderInfo.expressNo = res.data.machines[0].expressNo
          // this.orderInfo.imei = res.data.machines[0].imei
          // this.orderInfo.phoneName = res.data.machines[0].phoneName
          this.orderInfoArr[0].orderNo = res.data.machines[0].recycleOrderNo
          this.orderInfoArr[0].purchasePrice = res.data.machines[0].purchasePrice
          this.orderInfoArr[0].isCurrentMerchantAck = res.data.machines[0].isCurrentMerchantAck
          this.orderInfoArr[0].merchantName = res.data.machines[0].ackMerchantName
          
          // this.orderInfo.expressNoExpand = res.data.machines[0].expressNoExpand
          // this.orderInfo.expressIsTrue = res.data.machines[0].expressIsTrue
          // this.orderInfo.merchantName = res.data.machines[0].ackMerchantName
          // this.orderInfo.ackTime = res.data.machines[0].ackTime
          this.machines.recycleOrderNo = res.data.machines[0].recycleOrderNo || ""
          this.manualFlag = res.data.enterType == '01' ? true :false
          this.isSave = true
          console.log('lalala',this.orderInfoArr,this.manualFlag,this.detailFlag);
          if (type == 'save') {
            this.newTipShow2 = false
            this.printBatchNo()
          }
        }
      })
    },
    infoChecked() {
      if (!this.isAllMachineInHouse) {
        this.$message.error('该单据已出库，不可修改信息。')
        return
      }
      this.$message.error('请补全信息')
    },
    infoPrinter() {
      if (!this.intoNo) {
        this.$message.error('请先保存')
      }
    },
    printBatchNo() {
      // let exchangeUnitName = ""
      // this.exchangeUnitList.forEach(item => {
      //   if(this.supplierId == item.id){
      //     exchangeUnitName = item.exchangeUnitName
      //   }
      // })

      // this.LODOP = getLodop();
      // this.LODOP.SET_PRINT_PAGESIZE(1, '40mm', '30mm', "")
      // this.LODOP.ADD_PRINT_TEXT(10, 0, '36mm', 0, exchangeUnitName)
      // this.LODOP.SET_PRINT_STYLEA(0,"FontSize",10)
      // this.LODOP.SET_PRINT_STYLEA(0,"Alignment",2)
      // this.LODOP.ADD_PRINT_BARCODE(25,0,'40mm','15mm',"qrcode", this.orderInfo.imei);
      // // this.LODOP.ADD_PRINT_TEXT(75, 0, '40mm', 0, "打印日期:" + moment(new Date()).format("YYYY-MM-DD"))
      // this.LODOP.ADD_PRINT_TEXT(30, '14mm', '40mm', 0, this.orderInfo.phoneName)
      // this.LODOP.SET_PRINT_STYLEA(0,"TextNeatRow",true);
      // this.LODOP.SET_PRINT_STYLEA(0,"FontSize",7)
      // this.LODOP.ADD_PRINT_TEXT(60, '14mm', '40mm', 0, this.orderInfo.imei)
      // this.LODOP.SET_PRINT_STYLEA(0,"FontSize",7)
      // this.LODOP.ADD_PRINT_TEXT(90, 0, '40mm', 0, "入库:" + this.createTime)
      // this.LODOP.SET_PRINT_STYLEA(0,"FontSize",8)
      // setTimeout(()=>{
      //   // this.LODOP.PREVIEW(); //预览打印
      //   this.LODOP.PRINT(); // 直接打印
      //   // this.LODOP.PRINT_DESIGN();//设计模式打印
      // },300)
      
        _api.getPrintImeiInfo({ intoNo: this.intoNo }).then(res => {
          if (res.code === 1) {
            var lineCode = res.data.barCode || "--"
            // var topname = ""
            // if (res.data.enablePrintCompanyName) {
            //   topname = res.data.intoSimpleSupplierName + "&" + res.data.printCompanyShortName
            // } else {
            //   topname = res.data.intoSupplierName
            // }
            console.log('打印',res.data);
            this.LODOP = getLodop();
            this.LODOP.SET_PRINT_PAGESIZE(1, '40mm', '30mm', "")
            this.LODOP.ADD_PRINT_TEXT(10, 0, '36mm', 0, res.data.intoSimpleSupplierName)
            this.LODOP.SET_PRINT_STYLEA(0, "FontSize", 10)
            this.LODOP.SET_PRINT_STYLEA(0, "Alignment", 2)
            this.LODOP.ADD_PRINT_BARCODE(25, 0, '40mm', '15mm', "qrcode", res.data.imei);
            // this.LODOP.ADD_PRINT_TEXT(75, 0, '40mm', 0, "打印日期:" + moment(new Date()).format("YYYY-MM-DD"))
            this.LODOP.ADD_PRINT_TEXT(30, '14mm', '40mm', 0, res.data.phoneSku)
            this.LODOP.SET_PRINT_STYLEA(0, "FontSize", 7)
            this.LODOP.ADD_PRINT_TEXT(48, '14mm', '40mm', 0, "条码:" + lineCode)
            this.LODOP.SET_PRINT_STYLEA(0, "FontSize", 7)
            this.LODOP.ADD_PRINT_TEXT(65, '14mm', '40mm', 0, res.data.imei)
            this.LODOP.SET_PRINT_STYLEA(0, "FontSize", 7)
            this.LODOP.ADD_PRINT_TEXT(90, 0, '40mm', 0, "入库:" + res.data.intoTime)
            this.LODOP.SET_PRINT_STYLEA(0, "FontSize", 8)
            setTimeout(() => {
              // this.LODOP.PREVIEW(); //预览打印
              this.LODOP.PRINT(); // 直接打印
              // this.LODOP.PRINT_DESIGN();//设计模式打印
            }, 300)
          }else{
            this.$message.error(res.msg || '系统未知错误！')
          } 
        }) 
    },
    newlyBuild() {
      //
      if (this.warehouseId && this.supplierId && this.orderInfo.phoneName && this.orderInfo.imei && this.orderInfo.expressNo && (this.orderInfo.expressIsTrue === false ? this.orderInfo.expressNoExpand : true) && this.isAllMachineInHouse) {
        this.preservation('new')
      } else {
        this.newTipShow = true
      }
    },
    handleScrollLeft() {
      let offsetVal =
        this.$refs.barContainer.scrollLeft - this.offset > 0
          ? this.$refs.barContainer.scrollLeft - this.offset
          : 0

      let timer = setInterval(() => {
        this.$refs.barContainer.scrollLeft -= 2
        if (this.$refs.barContainer.scrollLeft <= offsetVal) {
          window.localStorage.setItem(
            'imgTabsBar',
            this.$refs.barContainer.scrollLeft
          )
          clearInterval(timer)
        }
      }, 5)
    },
    handleScrollRight() {
      let offsetVal = this.$refs.barContainer.scrollLeft + this.offset
      let maxVal =
        this.$refs.barContainer.scrollWidth -
        this.$refs.barContainer.clientWidth
      let timer = setInterval(() => {
        this.$refs.barContainer.scrollLeft += 2
        if (
          this.$refs.barContainer.scrollLeft >= offsetVal ||
          this.$refs.barContainer.scrollLeft >= maxVal
        ) {
          window.localStorage.setItem(
            'imgTabsBar',
            this.$refs.barContainer.scrollLeft
          )
          clearInterval(timer)
        }
      }, 5)
    },
    // 确定新建
    newSubmit() {
      this.intoNo = ""
      this.supplierId = ""
      this.remark = ""
      this.orderInfo = {
        phoneName: "",
        expressIsTrue: true,
        barCode: "",
        imei: "",
        expressNo: "",
        expressNoExpand: "",
        purchasePrice:""
      },
        this.machines = {
          barCode: '',
          expressIsTrue: true,
          expressNo: '',
          expressNoExpand: '',
          imei: '',
          phoneName: '',
          recycleOrderNo: '',
        },
        this.createTime = moment(new Date()).format("YYYY-MM-DD")
      this.operationUser = localStorage.getItem('userName') || ""
      this.warehouseSelect();
      this.supplierIntoSelect()
      this.getFastNote()
      this.newTipShow = false
      this.newTipShow2 = false
      this.manualFlag = false
      this.isSave = false
    },
    visibleyouchange(e) {
      if (e) {
        this.exchangeUnitList2 = this.exchangeUnitList;
      }
    },
    dataFilter(val) {
      if (val) { //val存在
        this.exchangeUnitList2 = this.exchangeUnitList.filter((item) => {
          if (item.exchangeUnitName.indexOf(val) > -1 || item.exchangeUnitName.toUpperCase().indexOf(val.toUpperCase()) > -1 || item.exchangeShortName.indexOf(val) > -1 || item.exchangeShortName.toUpperCase().indexOf(val.toUpperCase()) > -1) {
            return true
          }
        })
      } else { //val为空时，还原数组
        this.exchangeUnitList2 = this.exchangeUnitList;
      }
    }
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.create_outbound {
  color: #333;

  .flex {
    display: flex;
    align-items: center;
  }

  .flex-jb {
    display: flex;
    justify-content: space-between;
  }

  .flex-c {
    display: flex;
    flex-direction: column;
  }

  .flex-jc {
    display: flex;
    justify-content: center;
  }

  .fs-16 {
    font-size: 16px;
  }

  .fs-14 {
    font-size: 14px;
  }

  .txt-2 {
    color: #4c84ff;
  }

  .font-bold {
    font-weight: bold;
  }

  .mb-15 {
    margin-bottom: 15px;
  }

  .ml-50 {
    margin-left: 50px;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .bottom-border {
    border-bottom: 1px solid #eff2f7;
  }

  // 备注说明
  .Remarks2 {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
  }

  .card {
    width: 100%;
    background: #fff;
    border-radius: 4px;

    .card_header {
      height: 60px;
      padding: 0 18px;
      box-sizing: border-box;

      .icon {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
    }

    .card_body {
      padding: 0 15px 25px;
      box-sizing: border-box;

      .card_bg_box {
        padding-left: 15px;
        width: 100%;
        height: 60px;
        background: #f5f6fa;
        border-radius: 4px;
      }

      .fast_note_list {
        .note_item {
          padding: 0 15px;
          height: 32px;
          background: #F9FBFD;
          border: 1px solid #D9E6FF;
          border-radius: 4px;
          margin-right: 20px;
          font-size: 14px;
          color: #333333;
          line-height: 32px;
          cursor: pointer;
        }
      }

      .img_box {
        position: relative;
        display: flex;
        align-items: center;
        width: 99.9%;
        height: 190px;
        background: #FFFFFF;
        border: 1px solid #E4ECFD;
        border-top: none;
        padding: 0 38px 0 23px;

        .left_btn {
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          left: 0;
          width: 20px;
          height: 60px;
          cursor: pointer;
        }

        .right_btn {
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          right: 0;
          width: 20px;
          height: 60px;
          cursor: pointer;
        }
      }

      .img_list {
        height: 190px;
        border-radius: 0px 0px 4px 4px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        white-space: nowrap;
        overflow-x: scroll;

        /*滚动条样式设置*/
        &::-webkit-scrollbar {
          width: 0.2em;
          height: 0.2em;
        }

        &:hover::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.2);
          border-radius: 0.5em;
          -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
        }

        /*滑块*/
        &::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.05);
          border-radius: 0.5em;
          -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
        }

        /*滑块轨道*/
        &::-webkit-scrollbar-track {
          border-radius: 0.5em;
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
        }

        .img_item {
          min-width: 160px;
          max-width: 160px;
          height: 160px;
          border-radius: 6px;
          margin-left: 15px;
          border: 1px solid #F9FBFD;
        }
      }
    }
  }

  .order-list:not(:first-child) {
    margin-top: 20px;
  }

  .order-list-01 {
    /deep/.el-table .el-table__header th {
      background: #D5E2FE !important;
      border-right: 1px solid #E4ECFD !important;
    }
  }

  .order-list-02 {
    /deep/.el-table .el-table__header th {
      background: #EBEBEB !important;
      border-right: 1px solid #EBEBEB !important;
    }
  }

  /deep/#order.el-table--border {
    border-radius: 6px 6px 0 0;
  }
  /deep/.el-input{
    width: 240px;
  }
}
</style>
